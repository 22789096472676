<template>
  <div class="history-video">
    <SearchBarVideo />
    <div class="container-video">
      <RingLoading v-if="searchLoading" />
      <div ref="videoCard" v-for="(video, index) in historyVideoList" :key="video.id">
        <VideoCard :video="video" :index="index" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'HistoryVideo',
  components: {
    SearchBarVideo: () => import('@/components/History/SearchBarVideo.vue'),
    VideoCard: () => import('@/components/History/HistoryContent/EventBoard/VideoCard.vue'),
    RingLoading: () => import('@/components/Base/RingLoading.vue'),
  },
  data() {
    return {
      
    }
  },
  computed: {
    // ...mapState('video', ['videoList', 'searchLoading', 'videoFocusIndex']),
    ...mapState('historyVideo', ['historyVideoList', 'searchLoading', 'videoFocusIndex']),
    // ...mapState('historyFr', ['frEventList', 'searchLoading', 'frFocusIndex']),
  },
  watch: {
    videoFocusIndex() {
      this.$nextTick(() => {
        const el = this.$refs.videoCard[this.videoFocusIndex]
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      })
    },
  },
  mounted() {
    this.updateHistoryEventModalType('video')
  },
  beforeDestroy() {
    this.resetState()
  },
  methods: {
    ...mapMutations('history', ['updateHistoryEventModalType']),
    ...mapMutations('historyVideo', ['resetState']),
    onCancel() {},
  }
}
</script>

<style lang="scss" scoped>
.history-video {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;

  .container-video {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 10px 20px;
    overflow-y: overlay;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    box-sizing: border-box;
    background: #282942;
    position: relative;
  }
}
</style>